import Banner from "../core/Banner";
import Section from "../core/Section";
import media1 from "../../src/resources/media/14u Mustangs.jpg";
import media2 from "../../src/resources/media/16u Mustangs.jpeg";
import media4 from "../../src/resources/media/EJ16U.jpg";
import media5 from "../../src/resources/media/EJ14U.jpg";
import media6 from "../../src/resources/media/IMG_6206.jpg";
import media7 from "../../src/resources/media/IMG_8014.jpg";

const SECTIONS = {
    main: {subPath: "main", title: "Accomplishments"},
};

export default function Media() {
    return (
        <>
            <Banner
                title="Media"
                headline={`Media of the Mustangs Family`}
                subLinks={Object.values([])}
            />
            <Section {...SECTIONS.main}>
                <div className="grid grid-cols-3 gap-4 m-12">
                    <div>
                        <img
                            src={media4}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                    <div>
                        <img
                            src={media5}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                    <div>
                        <img
                            src={media2}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                    <div>
                        <img
                            src={media1}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                    <div>
                        <img
                            src={media6}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                    <div>
                        <img
                            src={media7}
                            className="rounded-lg h-full w-full pb-2 object-contain object-cover"
                        />
                        <p className="font-bold uppercase "></p>
                    </div>
                </div>
                <div className="h-96"/>

            </Section>
        </>
    );
}
