import { FieldConfig, useField } from "formik";
import { FieldBaseProps } from "./FieldBase";

export default function Field(props: FieldProps) {
  const [field, meta] = useField(props);

  return (
    <div className="rounded-md shadow-sm w-full">
      <label
        htmlFor={props.id || props.name}
        className="block text-sm uppercase font-medium w-full"
      >
        {props.label}
      </label>
      <input
        {...field}
        {...props}
        className={`border-2 mt-2 rounded-md py-1 px-3 text-black mr-2`}
      />
      <span>{props.text}</span>
      {meta.touched && meta.error ? (
        <div className="text-xs font-bold mt-2 text-red-400">{meta.error}</div>
      ) : null}
    </div>
  );
}

export interface FieldProps extends FieldConfig {
  id?: string;
  label: string;
  text: string;
}
