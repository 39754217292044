import staffPlaceholder from "../../src/resources/logo.png";

export default function Staff(props: StaffProps) {
    return (
        <div className="flex flex-row p-4 bg-black bg-opacity-20 rounded-lg">
            <div className="flex flex-col items-center text-center w-64 pb-4">
                <img
                    src={props.imgSrc ?? staffPlaceholder}
                    className="rounded-lg h-72 w-72 pb-2 object-contain"
                />
                <p className="font-medium text-2xl uppercase">{props.name}</p>
                <p className="font-small text-md uppercase">{props.title}</p>
                <a className="font-bold text-lg uppercase" href={`mailto:${props.email}`}>
                    {props.email}
                </a>
                <a className="font-bold text-lg uppercase" href={`tel:${props.phone}`}>
                    {props.phone}
                </a>
            </div>
            <ul className="pl-4 w-96 font-medium text-sm uppercase list-none flex flex-col my-auto">{props.bio?.split('\n').map(line => <li className="pb-3">{line}</li>)}</ul>
        </div>
    );
}

export interface StaffProps {
    title: string;
    name: string;
    bio?: string;
    email?: string;
    phone?: string;
    imgSrc?: string;
}
