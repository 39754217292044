import { Link, useLocation } from "react-router-dom";
import React from "react";
import logo from "../resources/logo.png";

export default function Header(props: HeaderProps) {
  // Router location
  const location = useLocation();

  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-gray-700 py-2 px-8 top-0 w-full">
      {/*Left section of header*/}
      <div className="flex items-center">
        <img
          src={logo}
          alt="Long Island Mustangs logo"
          className="h-16 lg:h-12"
        />
        <p className="pl-4 text-white text-2xl font-bold hidden lg:block">
          Long Island Mustangs
        </p>
      </div>

      {/*Right section of header*/}
      <div className="grid grid-cols-3 lg:flex lg:space-y-0 lg:flex-row lg:space-x-8 my-4">
        {/*Create each link to the pages*/}
        {props.links.map((link) => {
          return link.external ? (
            <a
              key={link.path}
              href={link.path}
              className={`rounded-md text-white text-md px-3 py-2 text-center`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          ) : (
            <Link
              key={link.path}
              to={link.path}
              className={`rounded-md text-white text-md px-3 py-2 text-center ${
                location.pathname === link.path
                  ? "bg-gray-800 font-bold"
                  : "font-medium"
              }`}
            >
              {link.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

interface HeaderProps {
  links: HeaderLink[];
}

/**
 * Link on the Header Bar
 */
export interface HeaderLink {
  path: string;
  title: string;
  external?: boolean;
}
