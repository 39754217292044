import FieldBase, { FieldBaseProps } from "./FieldBase";
import { useField } from "formik";

export default function FieldText(props: FieldTextProps) {
  const [field, meta] = useField(props);

  return (
    <FieldBase {...props}>
      <input
        {...field}
        {...props}
        className={`border-2 mt-2 rounded-md block py-1 px-3 text-black w-full
                        ${
                          props.invertColor
                            ? "focus:ring-gray-500 focus:border-gray-500"
                            : "focus:ring-red-500 focus:border-red-500"
                        }
                           
                   `}
      />
    </FieldBase>
  );
}

export interface FieldTextProps extends FieldBaseProps {
  placeholder: string;
  required?: boolean;
  invertColor?: boolean;
}
