import dayjs from "dayjs";
import {DateSpan} from "../util/dayjs";

export const tryoutAgeGroups: { [key: string]: TryoutAgeGroup } = {
    // "10U": {
    //     yearStart: 2014,
    //     yearEnd: 2018,
    //     dates: [
    //         {
    //             start: dayjs("March 23, 2024 13:00:00"),
    //             end: dayjs("March 23, 2024 14:00:00"),
    //         },
    //     ],
    //     name: "10U",
    // },
    // "12U": {
    //     yearStart: 2012,
    //     yearEnd: 2013,
    //     dates: [
    //         {
    //             start: dayjs("March 23, 2024 13:00:00"),
    //             end: dayjs("March 23, 2024 14:00:00"),
    //         },
    //     ],
    //     name: "12U",
    // },
    "14U": {
        yearStart: 2010,
        yearEnd: 2011,
        dates: [
            {
                start: dayjs("April 20, 2024 13:00:00"),
            }
            , {
                start: dayjs("April 27, 2024 13:00:00"),
            }
        ],
        name: "14U",
    },
    "16U": {
        yearStart: 2008,
        yearEnd: 2009,
        dates: [
            {
                start: dayjs("April 20, 2024 20:30:00"),
            }
            , {
                start: dayjs("April 27, 2024 20:30:00"),
            }
        ],
        name: "16U",
    },
    "18U": {
        yearStart: 2006,
        yearEnd: 2007,
        dates: [
            {
                start: dayjs("April 21, 2024 20:30:00"),
            }
            , {
                start: dayjs("April 28, 2024 20:30:00"),
            }
        ],
        name: "18U",
    },
};

interface TryoutAgeGroup {
    yearStart: number;
    yearEnd: number;
    dates: DateSpan[];
    name: string;
}
