const axios = require("axios").default;

const isDev = false;
const localURL = "http://localhost:4000/";
const remoteURL = "https://us-central1.gcp.data.mongodb-api.com/app/application-0-ibttg/endpoint/";

export default axios.create({
    baseURL: isDev ? localURL : remoteURL,
    responseType: "json",
});
