import { useField } from "formik";
import FieldBase, { FieldBaseProps } from "./FieldBase";

export default function FieldDropdown(props: FieldBaseProps) {
  const [field, meta] = useField(props);

  return (
    <FieldBase {...props}>
      <select
        {...field}
        {...props}
        className={`border-2 mt-2 rounded-md block py-1 px-3 text-black w-full`}
      />
    </FieldBase>
  );
}
