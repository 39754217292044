import { FieldConfig, useField } from "formik";

export default function FieldBase(props: FieldBaseProps) {
  const [field, meta] = useField(props);

  return (
    <div className="rounded-md shadow-sm w-full">
      <label
        htmlFor={props.id || props.name}
        className="block text-sm uppercase font-medium w-full"
      >
        {props.label}
      </label>
      {props.children}
      {meta.touched && meta.error ? (
        <div className="text-xs font-bold mt-2 text-red-400">{meta.error}</div>
      ) : null}
    </div>
  );
}

export interface FieldBaseProps extends FieldConfig {
  id?: string;
  label: string;
}
