import Section from "../core/Section";
import Banner, { SubLink } from "../core/Banner";
import tryoutsImage from "../resources/tryouts/signal-2024-05-08-163159_002.jpeg";
import { Link } from "react-router-dom";

const SUB_LINKS: SubLink[] = [
    {
        title: "Join",
        subPath: "featured",
    },
    // { title: "Our Principles", subPath: "principles" },
];

export default function Home() {
    return (
        <>
            <Banner
                title="Long Island Mustangs"
                headline="Welcome to the home of the Mustangs!"
                subLinks={SUB_LINKS}
            />
            <Section
                subPath="featured"
                title="JOIN THE MUSTANGS!"
            >
                <div className="grid items-center grid-cols-1 w-full xl:w-3/5 2xl:w-2/5">
                    <img
                        src={tryoutsImage}
                        className="rounded-3xl p-4 object-contain w-full"
                        alt="Come join the Mustangs!"
                    />
                </div>
            </Section>
            {/* <Section subPath="principles" title="Our Principles">
        <HomePrinciples />
      </Section> */}
        </>
    );
}
