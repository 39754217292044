import {useRouteMatch} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import bannerImage from "../resources/banner/2022_congressional-cup-fall_16u_low-res.jpg";

export default function Banner(props: BannerProps) {
    const routeMatch = useRouteMatch();

    return (
        <div
            className="flex flex-col justify-between space-y-4 items-center py-8 bg-cover"
            style={{
                height: "500px",
                backgroundImage: `
                     linear-gradient(
                      rgba(25, 25, 35, 0.8), 
                      rgba(25, 25, 35, 0.95)
                    ),url(${bannerImage})
                    `,
                backgroundPosition: '50% 25%',
            }}
        >
            <div/>
            <div className="flex flex-col items-center">
                <h1 className="text-6xl font-bold text-white uppercase text-center">
                    {props.title}
                </h1>
                <i className="text-xl text-white mt-2 text-center">{props.headline}</i>
            </div>
            <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
                {props.subLinks.map((subLink) => (
                    <HashLink
                        key={subLink.subPath}
                        to={{pathname: routeMatch.url, hash: subLink.subPath}}
                        className="text-white text-md font-light p-2 rounded bg-gray-700 uppercase hover:bg-red-800"
                    >{`${subLink.title}`}</HashLink>
                ))}
            </div>
        </div>
    );
}

interface BannerProps {
    title: string;
    headline: string;
    subLinks: SubLink[];
}

export interface SubLink {
    title: string;
    subPath: string;
}
