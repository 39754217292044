import React from "react";

export default function Button(props: ButtonProps) {
  return (
    <button
      type={props.type ?? "button"}
      className={`rounded-md py-2 px-12 shadow-xl font-bold uppercase ${
        props.positive ? "bg-green-700" : "bg-red-700"
      } ${props.disabled ? "opacity-25" : "opacity-100"}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.isLoading && (
        <svg
          className="inline animate-spin mr-3 h-5 w-5 rounded-full border-1 border-t-4 border-gray-200"
          viewBox="0 0 24 24"
        />
      )}
      {!props.isLoading && props.children}
    </button>
  );
}

interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  disabled?: boolean;
  positive?: boolean;
  block?: boolean;
}
