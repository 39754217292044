import {Route, Switch} from "react-router-dom";
import Header, {HeaderLink} from "./Header";
import Home from "../home/Home";
import Tryouts from "../tryouts/Tryouts";
import Coaching from "../coaching/Coaching";
import Media from "../media/Media";

const PAGES: Page[] = [
    {content: <Home/>, title: "Home", path: "/", exact: true},
    {content: <Tryouts/>, title: "2024-2025 Tryouts", path: "/tryouts", exact: true},
    {content: <Coaching/>, title: "Coaching Staff", path: "/coaching"},
    {content: <Media/>, title: "Media", path: "/media"},
    // {content: <Roster/>, title: "Teams", path: "/teams"},
    // {content: <Contacts/>, title: "Team Contacts", path: "/contacts"},
    // {content: <Rinks/>, title: "Rinks", path: "/rinks"},
    // { content: <Event />, title: "UBS Arena Showcase", path: "/event" },
];

const EXTERNAL_LINKS: HeaderLink[] = [
    {
        title: "Team Store",
        path: "https://teamlocker.squadlocker.com/#/lockers/long-island-mustangs?_k=l3u25e",
        external: true,
    },
];

export default function App(props: AppProps) {
    return (
        <div className="bg-gray-900 min-h-screen">
            <Header
                links={([] as HeaderLink[]).concat(PAGES).concat(EXTERNAL_LINKS)}
            />
            <Switch>
                {PAGES.map((page) => {
                    return (
                        <Route key={page.path} exact={page.exact} path={page.path}>
                            {page.content}
                        </Route>
                    );
                })}
            </Switch>
        </div>
    );
}

interface AppProps {
}

export interface Page extends HeaderLink {
    content: JSX.Element;
    exact?: boolean;
}
