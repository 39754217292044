import staffKen from "../../src/resources/staff-photos/staff-ken-grosso.jpg";
import staffRussell from "../../src/resources/staff-photos/staff-russell-steiner.jpg";
import staffDuane from "../../src/resources/staff-photos/staff-duane-kilmer.jpg";
import staffRuss from "../../src/resources/staff-photos/staff-russ.jpg";
import staffVinny from "../../src/resources/staff-photos/staff-vinny-filippelli.jpg";
import staffJamie from "../../src/resources/staff-photos/staff-jamie-asuncion.jpg";
import Banner from "../core/Banner";
import Section from "../core/Section";
import Staff from "../general/Staff";

const SECTIONS = {
    // U12: { subPath: "12U", title: "12U Coaches" },
    U14: {subPath: "14U", title: "14U Coaches"},
    U16: {subPath: "16U", title: "16U Coaches"},
    U18: {subPath: "18U", title: "18U Coaches"},
    // skills: {subPath: "skills", title: "Skills & Development"},
};

const CONTACTS = {
    // U12: [
    //
    // ],
    U14: [
        {
            imgSrc: staffRuss,
            title: "14U Head Coach",
            name: "Russ Lee",
            bio: `Player/coach over 40 years
Power skating instructor ISI ,USFSA 
USA HOCKEY registered
Coached at all levels from A, AA, AAA 8u-18u. 
Multiple LIAHL and EJ Championships
Helped advance numerous players to the next level `,
        },
    ],
    U16: [
        {
            imgSrc: staffVinny,
            title: "16U Head Coach",
            name: "Vinny Filippelli",
            bio: `Director of Hockey, Long Island Mustangs
Part of the Mustangs organization for 3 years now
Player/Coach over 40 years 
Head Coach of 16u Mustangs
Head Coach of 18u Mustangs
USA Hockey Certified Coaching level 4
Won multiple championships with high school and travel hockey
Helped advance numerous players to the next level of their hockey journey
Recently won EJ championships with 14u and 16u Long Island Mustangs for the 22/23 season`,
        }, {
            imgSrc: staffDuane,
            title: "16U Assistant Coach",
            name: "Duane Kilmer",
            bio:
                `
Player/coach for over 35 yrs. 
USA Hockey registered Level 4 coach. 

Coach Duane has coached players between the ages of 10u-18u.  
Head Coach for Kings Park/Commack JV and Varsity teams for the past 10yrs. As the Varsity coach, led the team to the NYSAHA High School State tournament 5 of the last 6yrs. 
Head Coach for the Mustangs Tier III ‘A’ for the last 4yrs. 
Led the Mustangs to become the Tier III EJ Champions, during the 2022-2023 season. 
Helped advance numerous players to the next level
                `,
        },
    ], U18: [
        {
            imgSrc: staffVinny,
            title: "18U Head Coach",
            name: "Vinny Filippelli",
            bio: `Director of Hockey, Long Island Mustangs
Part of the Mustangs organization for 3 years now
Player/Coach over 40 years 
Head Coach of 16u Mustangs
Head Coach of 18u Mustangs
USA Hockey Certified Coaching level 4
Won multiple championships with high school and travel hockey
Helped advance numerous players to the next level of their hockey journey
Recently won EJ championships with 14u and 16u Long Island Mustangs for the 22/23 season`,
        },
    ],
    skills: [],
};

export default function Coaching() {
    return (
        <>
            <Banner
                title="Coaching Staff"
                headline={`Team Coaching Staff`}
                subLinks={Object.values(SECTIONS)}
            />
            {/*<Section {...SECTIONS.U12} invertColor={true}>*/}
            {/*    <div className="grid md:grid-flow-col grid-rows-1 gap-4">*/}
            {/*        {CONTACTS.U12.map((contact) => (*/}
            {/*            <Staff key={contact.name} {...contact} />*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</Section>*/}
            <Section {...SECTIONS.U14}>
                <div className="grid md:grid-flow-col grid-rows-1 gap-4">
                    {CONTACTS.U14.map((contact) => (
                        <Staff key={contact.name} {...contact} />
                    ))}
                </div>
            </Section>
            <Section {...SECTIONS.U16} invertColor={true}>
                <div className="grid md:grid-flow-col grid-rows-1 gap-4">
                    {CONTACTS.U16.map((contact) => (
                        <Staff key={contact.name} {...contact} />
                    ))}
                </div>
            </Section>
            <Section {...SECTIONS.U18} invertColor={false}>
                <div className="grid md:grid-flow-col grid-rows-1 gap-4">
                    {CONTACTS.U18.map((contact) => (
                        <Staff key={contact.name} {...contact} />
                    ))}
                </div>
            </Section>
            {/*<Section {...SECTIONS.skills}>*/}
            {/*    <div className="grid md:grid-flow-col grid-rows-1 gap-4">*/}
            {/*        {CONTACTS.skills.map((contact) => (*/}
            {/*            <Staff key={contact.name} {...contact} />*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</Section>*/}
        </>
    );
}
