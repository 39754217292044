import React from "react";
import { SubLink } from "./Banner";

export default function Section(props: SectionProps) {
  return (
    <div
      id={props.subPath}
      className={`p-4 py-8 flex flex-col items-center text-center ${
        props.invertColor ? "bg-red-900" : "bg-gray-800"
      }`}
    >
      <h2
        id={props.subPath}
        className="text-2xl font-black uppercase mb-4 underline "
      >
        {props.title}
      </h2>
      {props.children}
    </div>
  );
}

export interface SectionDetails extends SubLink {}

interface SectionProps extends SectionDetails {
  children?: React.ReactNode;
  invertColor?: boolean;
}
